/** @format */

import React, { useContext, useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { Form, Button, InputGroup } from "react-bootstrap";
import Luhn from "luhn";
import "./pago.css";
import TarjetaNombre from "../../images/tarjeta-nombre.svg";
import TarjetaNumero from "../../images/tarjeta-numero.svg";
import TarjetaFecha from "../../images/tarjeta-fecha.svg";
import TarjetaCodigo from "../../images/tarjeta-codigo.svg";
import { AppContext } from "../../utils/ContextWrapper";
import { navigate } from "gatsby-link";
import { formatoNumero } from "../../utils/utils";
import ReCaptcha from "@pittica/gatsby-plugin-recaptcha";
import { Helmet } from "react-helmet";

function Pago() {
  const [loading, setLoading] = useState(false);
  const [validacion, setValidacion] = useState("");
  const [token, setToken] = useState("");
  const preciosEnvio = [0.0, 25.0, 30.0, 30.0, 40.0];

  const [valorTotalCompra, setValorTotalCompra] = useState(0.0);

  const [registroExitoso, setRegistroExitoso] = useState(false);

  const [submitted, setSubmitted] = useState(false);

  const context = useContext(AppContext);

  const [errores, setErrores] = useState({
    nombre: "",
    numero: "",
    fecha: "",
    cvc: "",
  });

  const [registroPago, setRegistroPago] = useState({
    nombre: "",
    numero: "",
    fecha: "",
    cvc: "",
  });

  const valorCompra = context.actions.obtenerTotal(context.store.carrito, context.store.porcentajeDescuento);
  // el valor del descuento
  const descuento = context.store.porcentajeDescuento;

  const handleSubmit = (recaptchaToken) => {
    setToken(recaptchaToken);
    setRegistroExitoso(false);
    setValidacion("");
    let valido = true;

    // se limpian los errores.
    let validacionErrores = { nombre: "", numero: "", fecha: "", cvc: "" };

    // se revisa que se han validado las propiedades.
    if (registroPago.nombre.length === 0) {
      validacionErrores.nombre = "El nombre de la tarjeta es requerido";
      valido = false;
    }
    if (registroPago.numero.length === 0) {
      validacionErrores.numero = "El número de tarjeta es requerido";
      valido = false;
    }
    if (registroPago.fecha.length === 0) {
      validacionErrores.fecha = "El campo de fecha es requerido";
      valido = false;
    }
    if (registroPago.fecha.length !== 5) {
      validacionErrores.fecha = "El campo de fecha debe ser de 5 letras y formato MM/YY";
      valido = false;
    }
    if (!registroPago.fecha.includes("/")) {
      validacionErrores.fecha = "El campo de fecha en formato MM/YY";
      valido = false;
    }

    if (registroPago.cvc.length === 0) {
      validacionErrores.cvc = "El código de verifiación es requerido";
      valido = false;
    }

    setErrores(validacionErrores);

    if (valido) {
      let productos = [];
      let carrito = context.store.carrito;
      Object.keys(carrito).forEach((key) => {
        productos.push({
          id: key,
          cantidad: carrito[key].cantidad,
          precioQuetzales: carrito[key].precioQuetzales,
          precioDolares: carrito[key].precioDolares,
        });
      });

      setLoading(true);

      fetch(`${process.env.API_URL_CHAPIN}/ChapinSinGlutenWS/rest/chapinWS/compras/registrarPedido`, {
        method: "POST",
        headers: {
          "Content-type": "application/json;",
          pedido: JSON.stringify({
            username: context.store.usuarioAutenticado.username,
            nombre: context.store.datosEnvio.nombre,
            apellido: context.store.datosEnvio.apellido,
            nit: context.store.datosEnvio.nit,
            email: context.store.datosEnvio.email,
            telefono: context.store.datosEnvio.telefono,
            direccion: context.store.datosEnvio.direccion,
            departamento: context.store.datosEnvio.departamento,
            codigoPostal: context.store.datosEnvio.codigoPostal,
            total: Number(context.actions.obtenerTotal(context.store.carrito)).toFixed(2),
            totalCompra: Number(
              calculaValorPago(
                preciosEnvio[context.store.envio],
                context.store.envio,
                context.actions.obtenerTotal(context.store.carrito, context.store.porcentajeDescuento)
              )
            ).toFixed(2),
            descuento: Number(
              context.actions.obtenerDescuentoTotal(context.store.carrito, context.store.porcentajeDescuento)
            ).toFixed(2),
            //context.store.usuarioAutenticado.codigo?.length > 0 ? Number(valorCompra * descuento).toFixed(2) : 0.0,
            envio: calculaValorEnvio(preciosEnvio[context.store.envio], context.store.envio, valorCompra),
          }),
          listado: JSON.stringify(productos),
          tarjeta: JSON.stringify(registroPago),
          token: recaptchaToken,
          sid: context.store.sid,
        },
      })
        .then(async (res) => {
          if (res.status === 200) {
            setRegistroExitoso(true);
            setValidacion("Tu pago ha sido exitoso, muy pronto podras disfrutar de nuestros productos.");
            navigate("/comprar/pago-completado/");
            // se asigna el codigo de pedido
            context.actions.setAprobacion(await res.text());
          } else if (res.status === 210) {
            // se limpia el codigo de pedido
            context.actions.setAprobacion("");
            setValidacion(await res.text());
            setSubmitted(false);
          } else {
            // se limpia el codigo de pedido
            context.actions.setAprobacion("");
            setValidacion(
              "No fue posible procesar el pago, revisa los datos ingresados e intenta nuevamente. Si el problema perisiste, comunicate con tu proveedor de tarjeta de crédito."
            );
            setSubmitted(false);
          }
          setLoading(false);
        })
        .catch((error) => {
          setValidacion(
            "Excepcion, No fue posible realizar el proceso, revisa tu conexión a internet e intenta nuevamente." + error
          );
          setLoading(false);
          setSubmitted(false);
        });
    } else {
      setSubmitted(true);
    }
  };

  const handleChange = (event) => {
    setSubmitted(false);
    if (event.target.name === "aceptaTerminos")
      setRegistroPago({ ...registroPago, [event.target.name]: event.target.checked });
    else setRegistroPago({ ...registroPago, [event.target.name]: event.target.value });

    if (event.target.name === "fecha") {
      // Validacion de la lonngitud
      if (event.target.value.length !== 5)
        setErrores({
          ...errores,
          [event.target.name]: "Se debe de ingresar la fecha en formato MM/YY",
        });
      else {
        // validación si la tarjeta se encuentra vencida.
        let valores = event.target.value.split("/");
        let error = "";
        let anio = parseInt(new Date().getFullYear().toString().substr(2, 2));
        let mes = new Date().getMonth();
        if (valores[0] >= 13) {
          error += "El mes no puede ser mayor a 12";
        }
        if (valores[1] < anio) {
          error += "El año no puede ser menor al año actual";
        }
        if (valores[0] < mes && parseInt(valores[1]) === anio) {
          error += "La tarjeta se encuentra vencida";
        }
        setErrores({
          ...errores,
          [event.target.name]: error,
        });
      }
    } else if (event.target.name === "cvc") {
      if (event.target.value.length < 3)
        setErrores({
          ...errores,
          [event.target.name]: "El código CVC debe ser de 3 o 4 dígitos",
        });
      else
        setErrores({
          ...errores,
          [event.target.name]: "",
        });
    } else if (event.target.name === "numero") {
      if (event.target.value.length !== 16)
        setErrores({
          ...errores,
          [event.target.name]: "El número de tarjeta debe contener al menos 16 dígitos.",
        });
      else if (!Luhn.validate(event.target.value)) {
        setErrores({ ...errores, [event.target.name]: "El número de tarjeta es incorrecto." });
      } else setErrores({ ...errores, [event.target.name]: "" });
    } else if (event.target.value.length === 0)
      setErrores({ ...errores, [event.target.name]: "El campo es requerido." });
    else setErrores({ ...errores, [event.target.name]: "" });
  };

  const calculaValorEnvio = (v_valor_envio, v_envio, v_compra) => {
    let envio = v_valor_envio;
    // si es guatemala o carretera y la compra es mallor o igual 300
    if (v_envio <= 2 && v_compra >= 300) {
      envio = 0;
    }
    return envio;
  };

  const calculaValorPago = (v_valor_envio, v_envio, v_compra) => {
    let envio = v_valor_envio;
    // si es guatemala o carretera y la compra es mayor o igual 300
    if (v_envio <= 2 && v_compra >= 300) {
      envio = 0.0;
    }
    let descuento = 0.0;
    // si tiene codigo de descuento
    //if (v_codigo?.length > 0) {
    //  descuento = v_compra * v_descuento;
    //}
    return envio + v_compra - Number(descuento).toFixed(2);
  };

  useEffect(() => {
    if (valorCompra === 0) {
      navigate("/comprar/");
      return null;
    }
  }, []);

  return (
    <Layout>
      <Helmet>
        <script
          type="text/javascript"
          src={`https://h.online-metrix.net/fp/tags.js?org_id=${process.env.FINGER_PRINT_CODE}&session_id=visanetgt_salsasa${context.store.sid}`}
        />
      </Helmet>
      <noscript>
        <iframe
          style={{ width: "100px", height: "100px", border: 0, position: "absolute", top: "-5000px" }}
          src={`https://h.online-metrix.net/fp/tags?org_id=${process.env.FINGER_PRINT_CODE}&session_id=visanetgt_salsasa${context.store.sid}`}
        ></iframe>
      </noscript>
      <div className="pagoTarjeta-container">
        <div className="pagoTarjeta-tarjeta pagoTarjeta-titulo-grande">Ingresa los datos de tu tarjeta de crédito:</div>
        <br />
        <div className="pagoTarjeta-tarjetaContainer">
          <Form>
            <Form.Group className="mb-3" controlId="txtNombre">
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">
                  <img src={TarjetaNombre} alt="imagen nombre tarjeta" />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Nombre del titular de la tarjeta"
                  name="nombre"
                  onChange={handleChange}
                  isInvalid={errores.nombre.length > 0}
                />
                <Form.Control.Feedback type="invalid">{errores.nombre}</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3" controlId="txtNumero">
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">
                  <img src={TarjetaNumero} alt="imagen número tarjeta" />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Número de tarjeta sin espacios ni guiones"
                  name="numero"
                  maxLength={16}
                  onChange={handleChange}
                  isInvalid={errores.numero.length > 0}
                />
                <Form.Control.Feedback type="invalid">{errores.numero}</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <div>
              <Form.Group className="mb-3" controlId="txtFecha">
                <InputGroup hasValidation>
                  <InputGroup.Text id="inputGroupPrepend">
                    <img src={TarjetaFecha} alt="imagen fecha tarjeta" />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="MM/AA"
                    value={registroPago.fecha}
                    maxLength={5}
                    name="fecha"
                    onChange={handleChange}
                    isInvalid={errores.fecha.length > 0}
                  />
                  <Form.Control.Feedback type="invalid">{errores.fecha}</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Form.Group className="mb-3" controlId="txtCodigoVerifica">
                <InputGroup hasValidation>
                  <InputGroup.Text id="inputGroupPrepend">
                    <img src={TarjetaCodigo} alt="imagen codigo tarjeta" />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="CVC"
                    name="cvc"
                    maxLength={3}
                    onChange={handleChange}
                    isInvalid={errores.cvc.length > 0}
                  />
                  <Form.Control.Feedback type="invalid">{errores.cvc}</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </div>
            <div className="d-flex  flex-column justify-content-end">
              <span style={{ color: "black" }}>
                <b>Valor de tu compra:</b> Q. {formatoNumero(valorCompra)}
                <br />
                <b>(+) Envío:</b> Q.{" "}
                {formatoNumero(calculaValorEnvio(preciosEnvio[context.store.envio], context.store.envio, valorCompra))}
                <br />
                {context.store.usuarioAutenticado.codigo?.length > 0 && (
                  <>
                    <b>(-) Descuento:</b> Q. {context.actions.obtenerDescuentoTotal(context.store.carrito)}
                    <br />
                  </>
                )}
              </span>

              <span
                style={registroExitoso ? { color: "green", fontWeight: "bold" } : { color: "red", fontWeight: "bold" }}
              >
                {validacion}
              </span>

              <div className="d-flex  flex-row">
                <Button onClick={() => setSubmitted(true)} className="btn-naranja m-3" active={!loading}>
                  {loading ? "Procesando..." : "Pagar"}
                </Button>
                <div className="pagoTarjeta-valor-a-pagar">
                  <span>
                    Q.{" "}
                    {formatoNumero(
                      calculaValorPago(
                        preciosEnvio[context.store.envio],
                        context.store.envio,
                        context.actions.obtenerTotal(context.store.carrito, context.store.porcentajeDescuento)
                      )
                    )}
                  </span>
                </div>
              </div>
              <ReCaptcha
                action="homepage"
                siteKey="6LebjFUdAAAAANEgQrpbhjMaeJUtH5HhtcCt3wyX"
                onVerify={(token) => handleSubmit(token)}
                submitted={submitted}
              />
            </div>
          </Form>
        </div>
      </div>
    </Layout>
  );
}

export default Pago;
